import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { StaticImage, GatsbyImage, getSrc } from 'gatsby-plugin-image'
import { HiOutlineArrowNarrowRight } from '@react-icons/all-files/hi/HiOutlineArrowNarrowRight'
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter'

import FullLayout from '../components/layout/fulllayout'
import FadeInSection from '../components/fadeinsection'
import ResponsiveHeader from '../components/responsiveheader'
import SEO from '../components/seo'

import content from "../content/shes-the-first.json"
import usePoller from "../hooks/usePoller"

const GetLoudForGirlsPage = () => {
    const [activeArtist, setActiveArtist] = useState({
        index: 0,
        image: undefined,
        artist: undefined
    })

    const { query: { edges: images } } = useStaticQuery(graphql`
        {
            query: allFile(filter: { relativeDirectory: { eq: "shesthefirst/work" } }) {
                edges {
                  node {
                    name
                      childImageSharp {
                          gatsbyImageData(layout: FULL_WIDTH)
                      }
                  }
                }
            }
        }
    `)

    usePoller(() => {
        let nextIndex
        if (activeArtist.artist) {
            nextIndex = activeArtist.index == (images.length - 1) ? 0 : activeArtist.index + 1
        } else {
            nextIndex = Math.floor(Math.random()*images.length)
        }

        const artist = content.drops.find(drop => drop.twitter.toLowerCase() == images[nextIndex].node.name)

        if (artist) {
            setActiveArtist({
                index: nextIndex,
                image: images[nextIndex].node.childImageSharp.gatsbyImageData,
                artist
            })
        }
    }, { interval: 5000 })

    return (
        <FullLayout bodyClasses="!bg-stf">
            <SEO title={content.title} description={content.description} image={getSrc(images[0].node)} />

            <div className="w-full container grid mx-auto px-4 md:px-8 h-half-screen">
                <StaticImage
                    src="../images/shesthefirst/banner.jpg"
                    className={`left-1/2 right-1/2 h-half-screen w-screen -ml-half-screen -mr-half-screen -mt-48 opacity-75`}
                    loading="eager"
                    layout="fullWidth"
                    style={{ gridArea: '1/1' }}
                />
                <div
                    className={`absolute inset-0 bg-gradient-to-t from-stf to-transparent h-half-screen`}
                    style={{ gridArea: "1/1" }}
                ></div>

                <div
                    className="grid w-full max-w-screen-lg mx-auto"
                    style={{ gridArea: "1/1" }}
                >
                    <FadeInSection>
                        <div className="text-center">
                            <ResponsiveHeader className="font-black uppercase" size={4}>{content.title}</ResponsiveHeader>
                            <ResponsiveHeader size={1}>Ensuring girls everywhere are educated, respected, and heard through the power of NFTs.</ResponsiveHeader>
                        </div>
                    </FadeInSection>
                </div>
            </div>
            
            <div className="bg-stf text-white pb-32 px-8 md:px-16 lg:px-32">
                <FadeInSection delay="0.25s">
                    <div className="container flex flex-col xl:flex-row mx-auto">
                        <div className="w-full xl:w-1/2 mr-0 mb-10 lg:mb-0 lg:mr-6">
                            <ResponsiveHeader size={4} className="uppercase font-black mb-6">
                                CHARITY COLLECTION FOR SHE'S THE FIRST
                            </ResponsiveHeader>

                            <p className="text-2xl md:text-2xl lg:text-3xl lg:leading-tight font-bold mb-6">
                                {content.description}
                            </p>

                            <p className="text-xl mb-6">We've teamed up with one of the world's leading charities—She's the First—that fights gender inequality through education to harness the power of community through NFTs.</p>
                            <p className="text-xl mb-6">The collection—depicting girls with megaphones—promotes the charity and their mission to ensure girls everywhere are educated, respected, and heard. </p>
                            <div className="flex flex-col xl:flex-row mb-6 xl:mt-10">
                                <div className="flex items-end">
                                    <span className="text-xl">
                                        The collection can be found on <a href="https://rarible.com/get-loud-for-girls/items" target="_blank" className="text-white">Rarible</a>
                                    </span>
                                </div>
                                <div className="xl:ml-3 mt-3">
                                    <StaticImage
                                        src="../images/shesthefirst/rarible.png"
                                        alt="Rarible Logo"
                                        height={40}
                                        width={40}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full xl:w-1/2 ml-0 mt-6 xl:mt-0 xl:ml-6">
                            {activeArtist.artist && <div className="relative">
                                <GatsbyImage
                                    image={activeArtist.image}
                                    className="object-cover w-full rounded-md"
                                    alt={activeArtist.artist.name}
                                />

                                <div className="text-lg text-white absolute bottom-0 p-6 w-full">
                                    <p><strong>Artist:</strong> {activeArtist.artist.name}</p>
                                    <p><strong>Drop:</strong> {activeArtist.artist.date}</p>
                                </div>
                            </div>}
                        </div>
                    </div>
                </FadeInSection>
            </div>

            <div className="relative bg-white text-black py-32 px-8 md:px-16 lg:px-32 shadow-xl">
                <div className="container flex mx-auto">
                    <div className="w-full lg:w-2/3" style={{ zIndex: "2" }}>
                        <ResponsiveHeader size={3} className="uppercase font-black mb-6">
                            THE CHARITY
                        </ResponsiveHeader>

                        <p className="text-2xl md:text-2xl lg:text-3xl lg:leading-tight font-bold mb-6">
                            Millions of girls worldwide are denied their rights.
                            This is unacceptable.
                        </p>
                        <p className="text-xl mb-6">
                            She's the First believe that local organizations are best positioned to serve girls in their communities, yet they are significantly under-resourced.
                            She’s the First teams up with grassroots organizations to make sure girls have a voice.
                        </p>
                        <a href="https://shesthefirst.org" target="_blank" className="inline-block mb-6 button-stf font-bold transition-colors duration-300 ease-in-out levitate px-2 md:px-4 text-md md:text-2xl items-center focus:outline-none">
                            <span className="inline-block py-3 ml-4">Visit She's the First <HiOutlineArrowNarrowRight className="inline mr-4" size="1.5em" /></span>
                        </a>

                        <div className="bg-black px-6 py-12 text-white">
                            <ResponsiveHeader size={1} className="font-black">HIGH IMPACT</ResponsiveHeader>
                            <p className="mb-6">Each year, She's the First reaches more than 138,000 girls across 26 countries through their work strengthening ecosystems and advocating for girls' rights.</p>
                            
                            <div className="flex flex-col md:flex-row">
                                <div className="w-full md:w-1/3 mb-6 md:mb-0 text-stf">
                                    <span className="text-2xl md:text-3xl lg:text-4xl font-black">102,900</span>
                                    <p className="font-bold uppercase text-xs">Girls reached through campus community and toolkits</p>
                                </div>

                                <div className="w-full md:w-1/3 mb-6 md:mb-0 text-stf">
                                    <span className="text-2xl md:text-3xl lg:text-4xl font-black">25,200</span>
                                    <p className="font-bold uppercase text-xs">Girls reached through trainings</p>
                                </div>

                                <div className="w-full md:w-1/3 mb-6 md:mb-0 text-stf">
                                    <span className="text-2xl md:text-3xl lg:text-4xl font-black">9,945</span>
                                    <p className="font-bold uppercase text-xs">Girls reached through partner programs</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="absolute bottom-0 right-0" style={{ zIndex: "1" }}>
                    <StaticImage
                        src="../images/shesthefirst/megaphone.png"
                        alt="She's the First"
                    />
                </div>
            </div>

            <div className="bg-stf text-white">
                <FadeInSection delay="0.35s">
                    <div className="py-32 container mx-auto">
                        <div className="mb-12 px-8 md:px-16 lg:px-32">
                            <ResponsiveHeader size={3} className="uppercase font-black mb-6">
                                THE DROP
                            </ResponsiveHeader>

                            <p className="text-xl mb-6">
                                Proceeds from the collection will be donated to She's the First via <a href="https://thegivingblock.com/" target="_blank" className="text-white">The Giving Block</a>.
                                To support the individual artists and ensure they are paid fairly for their work, the artists can optionally choose to donate between 50% and 100% of proceeds. 
                                100% royalties from all secondary sales will be donated to She's The First.
                            </p>

                            <p className="text-xl mb-6">
                                The drops will start on 1st January 2022 and will take place as auctions on Rarible.
                                To ensure there is time to appreciate each piece, there will be a max of 1 drop per day.
                                The current schedule is as follows:
                            </p>
                            
                            <p className="text-xl mb-6">
                                The collection will also be stored as part of <Link to="/non-fungible-vault/" className="text-white">Non-Fungible Vault</Link>. 
                                This means, the NFTs will be etched into a special purpose-built silver-halide film that is designed to last for over 1,000 years. 
                                The films are then stored in a decommissioned coal mine in the Svalbard archipelago, known as the Arctic World Archive.
                            </p>
                        </div>

                        <div className="grid gap-2 md:gap-2 grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                            {content.drops.map((drop, index) => {
                                //const image = images.find(i => i.node.name == content.drops[index].twitter.toLowerCase())

                                return <FadeInSection delay={0.1 + index / 10 + `s`}>
                                    <div className="flex bg-white text-black shadow-lg h-40 xs:h-44 lg:h-44 2xl:h-40">
                                        <p className="bg-black px-1 py-2 text-white transform -rotate-180 inline-block font-bold text-sm md:text-2xl lg:text-3xl uppercase" style={{ writingMode: "vertical-rl", textOrientation: "mixed" }}>
                                            {drop.date}
                                        </p>

                                        <div className="flex-1 w-auto p-6">
                                            {drop.name && <>
                                                <p className="mb-2">
                                                    <a href={`https://twitter.com/${drop.twitter}`} target="_blank" className="text-stf no-underline leading-3 font-black text-lg md:text-2xl uppercase">
                                                        <span>{drop.name}</span>
                                                        <FaTwitter className="inline-block ml-2" />
                                                    </a>
                                                </p>
                                                <p className="text-xs md:text-sm font-normal">{drop.description}</p>
                                                {drop.link && <>
                                                    <p className="mb-2">
                                                        <a href={drop.link} target="_blank" className="text-black text-xs md:text-sm font-normal">
                                                            <span>Link to art.</span>
                                                        </a>
                                                    </p>
                                                </>}
                                            </>}
                                            {!drop.name && <p className="italic text-gray-500">To be revealed...</p>}
                                        </div>
                                    </div>
                                </FadeInSection>
                            })}
                        </div>
                    </div>
                </FadeInSection>
            </div>

            <div className=" bg-black text-white py-32 px-8 md:px-16 lg:px-32">
                <div className="container">
                    <ResponsiveHeader size={3} className="uppercase font-black mb-6">
                        PRESS AND ARTIST ENQUIRES
                    </ResponsiveHeader>

                    <p className="text-xl">Press, media, or artist looking to involved? Reach out to abby@topdogbeachclub.com.</p>
                </div>
            </div>
        </FullLayout>
    )
}

export default GetLoudForGirlsPage